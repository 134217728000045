.card{
	background-color: var(--background-glass);
	box-shadow:       0 0 1em #0001;
	padding: 0;
	border-radius: 0.5em;
	min-width: 300px;
	width: 80%;
	max-width: 800px;
	margin: 1em 1em 2em 1em;
	display: inline-block;
	overflow: hidden;
	vertical-align: middle;
}


@media only screen and (min-width: 1020px) {
	.card{
		margin: 1em 4vw 2em 0;
	}
}

.card-title{
	text-align: center;
	font-size: 3em;
	height: 2ch;
	position: relative;
	color:       #fff;
	text-shadow: 0 0 1em #0003, 0 0 0.1em #0004;
}

.card-content{
	width: 100%;
	background-color: var(--background-glass);
	box-shadow:       0 0 1em #0001;
	color: #000;
	padding: 1em 2em 1em 2em;
	box-sizing: border-box;
	text-align: left;
}

.card li{
	margin-bottom: 0.5em;
}

.card h1{
	margin: 0.3em 0 0.15em 0;
}

.card h2{
	color: #222b;
	/*text-shadow: 0 0 0.08em #0008;*/
	font-size: 2em;
	margin: 0 0 0.5em 0;
}
.card h3{
	color: #222b;
	/*text-shadow: 0 0 0.08em #0008;*/
	font-size: 1.25em;
	margin: 0;
}
.card p{
	color: #000b;
	/*text-shadow: 0 0 0.08em #0008;*/
	font-size: 1em;
}