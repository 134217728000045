:root {
	--nameplate-opacity: 1;
}

#NamePlate {
	font-family:                "Public Sans", sans-serif;
	font-size:                  20em;
	font-weight:                900;
	color:                      #fff;
	width:                      100%;
	height:                     100%;
	align-content:              center;
	display:                    block;
	position:                   fixed;
	top:                        -15%;
	left:                       0;
	text-align:                 center;
	padding:                    0.5em 0;
	overflow:                   hidden;
	pointer-events:             none;
	opacity:                    var(--nameplate-opacity);
	transition-property:        opacity;
	transition-duration:        100ms;
	transition-timing-function: linear;
}

.NamePlateText {
	width:            120%;
	right:            10%;
	position:         relative;
	background-color: #fff4;
	box-shadow:       0 0 0.1em #0001;
	text-shadow:      0 0 0.1em #0001, 0 0 0.05em #0002;
}

#NamePlate-First {
	font-size:             7rem;
	font-style:            italic;
	transform:             rotate(-4deg);
	text-decoration:       underline;
	text-underline-offset: 0.1em;
	margin:                15vh 0 0.25em 0;
	padding-bottom:        0.15em;
}

#NamePlate-Second {
	transform: rotate(8deg);
}

#NamePlate-Third {
	top:       0.1em;
	transform: rotate(2deg);
}

@media only screen and (max-height: 500px) {
	#NamePlate {
		font-size: 5em;
	}

	#NamePlate-First {
		font-size: 2.5rem;
	}
}

@media only screen and (min-height: 500px) and (max-height: 650px) {
	#NamePlate {
		font-size: 6em;
	}

	#NamePlate-First {
		font-size: 3rem;
	}
}

@media only screen and (min-height: 650px) and (max-height: 800px) {
	#NamePlate {
		font-size: 8em;
	}

	#NamePlate-First {
		font-size: 4rem;
	}
}

@media only screen and (min-height: 800px) and (max-height: 1000px) {
	#NamePlate {
		font-size: 9em;
	}

	#NamePlate-First {
		font-size: 4rem;
	}
}

@media only screen and (min-height: 1000px) and (max-height: 1200px) {
	#NamePlate {
		font-size: 11em;
	}

	#NamePlate-First {
		font-size: 5rem;
	}
}

@media only screen and (min-height: 1200px) and (max-height: 1400px) {
	#NamePlate {
		font-size: 13em;
	}

	#NamePlate-First {
		font-size: 5rem;
	}
}

@media only screen and (min-height: 1400px) and (max-height: 1700px) {
	#NamePlate {
		font-size: 15em;
	}

	#NamePlate-First {
		font-size: 6rem;
	}
}


@media only screen and (max-width: 1000px) and (min-height: 1700px) {
	#NamePlate {
		font-size: 15em;
	}

	#NamePlate-First {
		font-size: 6rem;
	}
}

@media only screen and (max-width: 800px) and (min-height: 1200px) {
	#NamePlate {
		font-size: 11em;
	}

	#NamePlate-First {
		font-size: 5rem;
	}
}

@media only screen and (max-width: 600px) and (min-height: 650px) {
	#NamePlate {
		font-size: 8em;
	}

	#NamePlate-First {
		font-size: 4rem;
	}
}



/*@media only screen and (max-width: 800px) and (min-height: 1200px) {*/
/*	#NamePlate {*/
/*		font-size: 12em;*/
/*	}*/

/*	#NamePlate-First {*/
/*		font-size: 5rem;*/
/*	}*/
/*}*/