:root {
	--background-glass: #fff6;
	--content-opacity:  0;
	--font-display:     "Public Sans", sans-serif;
}

body {
	font-family: "Open Sans", sans-serif;
	margin:      0;
	padding:     0;
	position:    absolute;
	left:        0;
	top:         0;
	height:      100vh;
	width:       100%;
	font-size:   12pt;
	font-weight: 400;
	overflow:    hidden;
	color:       #fff;
	background:  rgb(216, 255, 76);
	background:  linear-gradient(135deg, rgba(216, 255, 76, 1) 0%, rgba(44, 149, 73, 1) 100%);
}

.no-select {
	-moz-user-select:    none;
	-ms-user-select:     none;
	-webkit-user-select: none;
	user-select:         none;
}

#root {
	position: absolute;
	top:      0;
	left:     0;
	width:    100%;
	height:   100%;
}

#page {
	position:   absolute;
	top:        0;
	left:       0;
	width:      100%;
	height:     100%;
	background: rgb(216, 255, 76);
	background: linear-gradient(135deg, rgba(216, 255, 76, 1) 0%, rgba(44, 149, 73, 1) 100%);
	overflow-y: scroll;
}

#Content {
	position:   absolute;
	top:        100%;
	height:     100%;
	opacity:    var(--content-opacity);
	box-sizing: border-box;
	width:      100%;
	padding:    2em 0 0 0;
	text-align: center;
}

@media only screen and (min-width: 1020px) {
	#Content {
		padding: 2em 0 0 4vw;
	}
}

h1, h2 {
	font-family: var(--font-display);
	font-weight: 900;
	display:     block;
}

h3, h4, h5, h6 {
	font-family: var(--font-display);
	font-weight: 700;
	display:     block;
}

/*!* width *!*/
/*::-webkit-scrollbar {*/
/*	width: 0.5rem;*/
/*	opacity:    var(--content-opacity);*/
/*}*/

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
/*	background: #afa2;*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*	background: rgba(54, 150, 23, 0.25);*/
/*}*/

/*!* Handle on hover *!*/
/*::-webkit-scrollbar-thumb:hover {*/
/*	background: rgba(54, 150, 23, 0.75);*/
/*}*/
